<template>
    <div>
        <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="110px">

            <el-form-item label="指标类型：" prop="type">
                <el-select v-model="AddDateModel.type" placeholder="请选择指标类型" @change="typeChange(AddDateModel.type)">
                    <el-option v-for="item in taroptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- <el-form-item label="工作量指标类型：" prop="workloadIndicatorType">
                <el-select v-model="AddDateModel.workloadIndicatorType" placeholder="请选择工作量指标类型" @change="worChange(AddDateModel.workloadIndicatorType)">
                    <el-option v-for="item in workoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->

            <el-form-item v-if="encodeShow" label="项目编码：" prop="encode">
                <el-input v-model="AddDateModel.encode" placeholder="请填写项目编码"></el-input>
            </el-form-item>

            <el-form-item v-if="clasdiShow" label="病种说明：" prop="destype">
                <el-input maxlength="250" show-word-limit type="textarea" v-model="AddDateModel.destype" placeholder="请填写病种说明"></el-input>
            </el-form-item>

            <el-form-item v-if="clasdiShow" label="主要诊断：" prop="maindia">
                <el-input maxlength="250" show-word-limit type="textarea" v-model="AddDateModel.maindia" placeholder="请填写主要诊断"></el-input>
            </el-form-item>

            <el-form-item v-if="clasdiShow" label="主要术式：" prop="mainmeth">
                <el-input maxlength="250" show-word-limit type="textarea" v-model="AddDateModel.mainmeth" placeholder="请填写主要术式"></el-input>
            </el-form-item>

            <el-form-item v-if="mesprShow" label="指标名称：" prop="name">
                <el-input maxlength="250" v-model="AddDateModel.name" placeholder="请填写指标名称"></el-input>
            </el-form-item>

            <el-form-item v-if="mesprShow" label="指标内涵：" prop="connotation">
                <el-input maxlength="250" show-word-limit type="textarea" v-model="AddDateModel.connotation" placeholder="请填写指标内涵"></el-input>
            </el-form-item>

            <el-form-item v-if="noShow" label="建议分值：" prop="suggscore">
                <el-input v-model="AddDateModel.suggscore" type="number" placeholder="请填写建议分值"></el-input>
            </el-form-item>

            <el-form-item v-if="noShow" label="特性：" prop="character">
                <el-select v-model="AddDateModel.character" placeholder="请选择特性" @change="charChange(AddDateModel.character)">
                    <el-option v-for="item in chaoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-if="mesprShow" label="单位：" prop="unit">
                <el-select v-model="AddDateModel.unit" placeholder="请选择单位" @change="unitChange(AddDateModel.unit)">
                    <el-option v-for="item in unitoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- 
            <el-form-item v-if="clasdiShow" label="分值：" prop="score">
                <el-input type="number" v-model="AddDateModel.score" placeholder="请填写分值"></el-input>
            </el-form-item> -->

            <el-form-item v-if="noShow" label="指标说明：" prop="remark">
                <el-input maxlength="250" show-word-limit type="textarea" v-model="AddDateModel.remark" placeholder="请填写指标说明"></el-input>
            </el-form-item>

            <el-form-item v-if="noShow" label="指标状态：" prop="status">
                <el-switch v-model="AddDateModel.status" active-color="#409EFF" inactive-color="#929292" active-value="0" inactive-value="1">
                </el-switch>
            </el-form-item>

            <div class="btnCen">
                <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addKeshi">{{diaBtn}}</el-button>
                 <el-button v-else type="info" :loading="addDepClickKing"></el-button>
            </div>

        </el-form>
    </div>
</template>

<script>
import _qs from "qs";
export default {
    props: [
        "diaBtn",
        "EditDateModel",
        "wardId",
        "dieId",
        "taroptions",
        "inlibId",
    ],
    data() {
        var nameValda = async (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("请填写指标名称"));
            } else {
                if (this.edValName == value) {
                    callback();
                } else {
                    let data = _qs.stringify({
                        name: value,
                        type: "1", //指标类型（0基础指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                    });
                    let { data: res } = await this.$axios.checkName(data);
                    // console.log(res);
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        callback();
                    } else {
                        return callback(new Error(res.msg));
                    }
                }
            }
        };
        return {
            encodeShow: false,
            addDepClick: true,
            addDepClickKing: false,
            edValName: "",
            AddDateModel: {
                name: "",
                encode: "",
                connotation: "",
                status: "0",
                remark: "",
                suggscore: "",
                score: "",
                character: "",
                unit: "",
                workloadIndicatorType: "",
                destype: "",
                maindia: "",
                mainmeth: "",
                type: "",
            },
            chaoptions: [
                {
                    value: "0",
                    label: "系数加分",
                },
                {
                    value: "1",
                    label: "系数减分",
                },
                {
                    value: "2",
                    label: "固定金额",
                },
            ],
            workoptions: [
                {
                    value: "0",
                    label: "病例病种赋分",
                },
                {
                    value: "1",
                    label: "医疗服务项目",
                },
            ],
            unitoptions: [
                {
                    value: "0",
                    label: "次",
                },
                {
                    value: "1",
                    label: "人/次",
                },
                {
                    value: "2",
                    label: "半天/次",
                },
                {
                    value: "3",
                    label: "天",
                },
            ],
            AddDateRules: {
                type: [
                    {
                        required: true,
                        message: "请选择指标类型",
                        trigger: "change",
                    },
                ],
                mainmeth: [
                    {
                        required: true,
                        message: "请输入主要术式",
                        trigger: "blur",
                    },
                ],
                maindia: [
                    {
                        required: true,
                        message: "请输入主要诊断",
                        trigger: "blur",
                    },
                ],
                destype: [
                    {
                        required: true,
                        message: "请输入病种说明",
                        trigger: "blur",
                    },
                ],
                unit: [
                    {
                        required: true,
                        message: "请选择单位",
                        trigger: "change",
                    },
                ],
                score: [
                    {
                        required: true,
                        message: "请输入分值",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        validator: nameValda,
                        trigger: "blur",
                    },
                ],
                encode: [
                    {
                        required: true,
                        message: "请输入项目编码",
                        trigger: "blur",
                    },
                ],
                connotation: [
                    {
                        required: true,
                        message: "请填写指标内涵",
                        trigger: "blur",
                    },
                ],
                // remark: [
                //     {
                //         required: true,
                //         message: "请填写指标说明",
                //         trigger: "blur",
                //     },
                // ],
                // suggscore: [
                //     {
                //         required: true,
                //         message: "请填写建议分值",
                //         trigger: "blur",
                //     },
                // ],
                character: [
                    {
                        required: true,
                        message: "请选择特性",
                        trigger: "change",
                    },
                ],
                workloadIndicatorType: [
                    {
                        required: true,
                        message: "请选择工作量指标类型",
                        trigger: "change",
                    },
                ],
            },
            clasdiShow: false, //病例病种赋分
            mesprShow: false, //医疗服务项目
            noShow: false,
            tarName: "",
            workloadIndicatorType: "",
        };
    },
    watch: {
        EditDateModel: {
            handler(newVal, oldVal) {
                this.echo();
            },
            deep: true,
        },
    },
    created() {
        this.echo();
    },
    methods: {
        charChange(val) {
            this.AddDateModel.character = val;
        },
        unitChange(val) {
            // console.log(val);
            this.AddDateModel.unit = val;
        },
        typeChange(val) {
            this.AddDateModel.type = val;
            // console.log(val);
            this.tarId = val;
            this.taroptions.forEach((arr) => {
                if (val == arr.id) {
                    // console.log(arr.name);
                    this.tarName = arr.name;
                    if (arr.name == "病历病种") {
                        // 病例病种赋分
                        this.clasdiShow = true;
                        this.mesprShow = false;
                        this.noShow = true;
                    } else {
                        // 医疗服务项目
                        this.mesprShow = true;
                        this.clasdiShow = false;
                        this.noShow = true;
                    }
                    if (arr.name == "病历病种") {
                        // 病例病种赋分
                        this.workloadIndicatorType = "0";
                        this.encodeShow = false;
                    } else if (arr.name == "医疗服务项目") {
                        // 医疗服务项目
                        this.workloadIndicatorType = "1";
                        this.encodeShow = true;
                    } else if (arr.name == "业务量") {
                        // 业务量
                        this.workloadIndicatorType = "2";
                        this.encodeShow = false;
                    }
                }
            });
        },
        // worChange(val) {
        //     this.$refs.AddDateRef.resetFields();
        //     this.AddDateModel.workloadIndicatorType = val;

        //     if (val == "0") {
        //         // 病例病种赋分
        //         this.clasdiShow = true;
        //         this.mesprShow = false;
        //         this.noShow = true;
        //     } else if (val == "1") {
        //         // 医疗服务项目
        //         this.mesprShow = true;
        //         this.clasdiShow = false;
        //         this.noShow = true;
        //     }
        // },
        echo() {
            if (this.EditDateModel.id) {
                if (
                    this.EditDateModel.indicatorSecondLibraryName == "病历病种"
                ) {
                    this.AddDateModel.maindia = this.EditDateModel.intension;
                    this.AddDateModel.mainmeth =
                        this.EditDateModel.mainTechniques;
                    this.AddDateModel.destype = this.EditDateModel.name;
                } else {
                    this.AddDateModel.name = this.EditDateModel.name;
                    this.AddDateModel.connotation =
                        this.EditDateModel.intension;
                }

                this.AddDateModel.encode = this.EditDateModel.encoded;

                if (
                    this.EditDateModel.indicatorSecondLibraryName == "病历病种"
                ) {
                    // 病例病种赋分
                    this.clasdiShow = true;
                    this.mesprShow = false;
                    this.noShow = true;
                } else {
                    // 医疗服务项目
                    this.mesprShow = true;
                    this.clasdiShow = false;
                    this.noShow = true;
                }

                if (
                    this.EditDateModel.indicatorSecondLibraryName ==
                    "医疗服务项目"
                ) {
                    this.encodeShow = true;
                } else {
                    this.encodeShow = false;
                }

                if (
                    this.EditDateModel.indicatorSecondLibraryName == "病历病种"
                ) {
                    // 病例病种赋分
                    this.workloadIndicatorType = "0";
                } else if (
                    this.EditDateModel.indicatorSecondLibraryName ==
                    "医疗服务项目"
                ) {
                    // 医疗服务项目
                    this.workloadIndicatorType = "1";
                } else if (
                    this.EditDateModel.indicatorSecondLibraryName == "业务量"
                ) {
                    // 业务量
                    this.workloadIndicatorType = "2";
                }

                this.edValName = this.EditDateModel.name;

                this.AddDateModel.remark = this.EditDateModel.explain;
                this.AddDateModel.status = this.EditDateModel.status + "";
                this.AddDateModel.suggscore = this.EditDateModel.suggestedScore;
                this.AddDateModel.score = this.EditDateModel.score;
                this.AddDateModel.unit = this.EditDateModel.unit + "";

                this.AddDateModel.character =
                    this.EditDateModel.characteristic + "";
                // 指标类型
                this.AddDateModel.type =
                    this.EditDateModel.indicatorSecondLibraryId;

                if (this.EditDateModel.workloadIndicatorType == null) {
                    this.AddDateModel.workloadIndicatorType = "";
                } else {
                    this.AddDateModel.workloadIndicatorType =
                        this.EditDateModel.workloadIndicatorType + "";

                    // if (
                    //     this.EditDateModel.indicatorSecondLibraryName ==
                    //     "病历病种"
                    // ) {
                    //     console.log(111)
                    //     // 病例病种赋分
                    //     this.clasdiShow = true;
                    //     this.mesprShow = false;
                    //     this.noShow = true;
                    // } else {
                    //     // 医疗服务项目
                    //     this.mesprShow = true;
                    //     this.clasdiShow = false;
                    //     this.noShow = true;
                    // }
                }
            }
        },
        addKeshi() {
            if (this.diaBtn == "新增") {
                // 新增
                this.addworkIn();
            } else if (this.diaBtn == "修改") {
                // 修改
                this.updateworkIn();
            }
        },
        clearadd() {
            this.edValName = "";
            this.AddDateModel = {
                name: "",
                encode: "",
                connotation: "",
                status: "0",
                remark: "",
                suggscore: "",
                score: "",
                character: "",
                unit: "",
                workloadIndicatorType: "",
                destype: "",
                maindia: "",
                mainmeth: "",
                type: "",
            };
            this.encodeShow = false;
            this.clasdiShow = false; //病例病种赋分
            this.mesprShow = false; //医疗服务项目
            this.noShow = false;

            this.$refs.AddDateRef.resetFields();
            this.$emit("listworkIn");
        },
        addworkIn() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    if (this.tarName == "病历病种") {
                        this.AddDateModel.name = this.AddDateModel.destype;
                        this.AddDateModel.connotation =
                            this.AddDateModel.maindia;
                    }
                    let data = _qs.stringify({
                        indicatorSecondLibraryId: this.tarId,
                        type: this.dieId, //指标类型（0基础指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                        encoded: this.AddDateModel.encode, //指标编码
                        name: this.AddDateModel.name, //指标名称（病历病种时为：病种说明）
                        intension: this.AddDateModel.connotation, //指标内涵（病历病种时为：主要诊断）
                        mainTechniques: this.AddDateModel.mainmeth, //主要术式（病历病种时为：主要术式）
                        suggestedScore: this.AddDateModel.suggscore, //建议分值
                        characteristic: this.AddDateModel.character, //指标特性（0加分 1减分）
                        score: this.AddDateModel.score, //分值
                        unit: this.AddDateModel.unit, //单位（0次  1人/次  2半天/次）
                        explain: this.AddDateModel.remark, //指标说明
                        workloadIndicatorType: this.workloadIndicatorType, //工作量指标类型（0病例病种赋分   1医疗服务项目）  该项参数在指标类型为工作量类型时为必传
                        status: this.AddDateModel.status, //状态（0启用  1停用）默认0
                    });
                    let { data: res } = await this.$axios.thirdAdd(data);

                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.clearadd();
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        updateworkIn() {
            this.$refs.AddDateRef.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                if (
                    this.EditDateModel.indicatorSecondLibraryName == "病历病种"
                ) {
                    this.AddDateModel.name = this.AddDateModel.destype;
                    this.AddDateModel.connotation = this.AddDateModel.maindia;
                }
                let data = _qs.stringify({
                    id: this.wardId, //指标id
                    indicatorSecondLibraryId: this.inlibId, //二级指标id
                    type: this.dieId, //指标类型（0基础指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                    encoded: this.AddDateModel.encode, //指标编码
                    name: this.AddDateModel.name, //指标名称（病历病种时为：病种说明）
                    intension: this.AddDateModel.connotation, //指标内涵（病历病种时为：主要诊断）
                    mainTechniques: this.AddDateModel.mainmeth, //主要术式（病历病种时为：主要术式）
                    suggestedScore: this.AddDateModel.suggscore, //建议分值
                    characteristic: this.AddDateModel.character, //指标特性（0加分 1减分）
                    score: this.AddDateModel.score, //分值
                    unit: this.AddDateModel.unit, //单位（0次  1人/次  2半天/次）
                    explain: this.AddDateModel.remark, //指标说明
                    workloadIndicatorType: this.workloadIndicatorType, //工作量指标类型（0病例病种赋分   1医疗服务项目）  该项参数在指标类型为工作量类型时为必传
                    status: this.AddDateModel.status, //状态（0启用  1停用）默认0
                });
                let { data: res } = await this.$axios.threUpdate(data);
                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 200) {
                    this.clearadd();
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.btnCen {
    text-align: center;
    .el-button {
        width: 150px;
    }
}

.el-textarea {
    width: 300px;
}
</style>
